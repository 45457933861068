import React, { Component } from 'react';
import Meta from './meta';
import 'mediaelement';
import 'mediaelement/build/mediaelementplayer.min.css';
import ToolBar from "./Tool";


class MediaElement extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef()
    }
    state = {}
    success(media, node, instance) {
        console.log("Play Succeed.", node)
        console.log(media)
        console.log(instance)
    }


    error(media) {
        console.log("Play Error.")
    }

    render() {
        const props = this.props;
        return (
            <div id="pl" ref={this.ref} className="w-full lg:h-2/3 h-1/3" onDoubleClick={() => { this.ref.current.requestFullscreen() }}>
                <div id="danmu" className="overflow-hidden z-10 w-280 lg:text-2xl lg:h-1/3 h-1/4 absolute bg-opacity-0 text-white text-xl" style={{ textShadow: '1px 1px 1px black' }}></div>
                <video autoPlay id={props.id} width="100%" height="100%" poster={props.poster} >
                    <source src={`/api${props.path}?only=live`} type={props.type} />
                </video>
            </div>
        );
    }


    componentDidMount() {
        const { MediaElementPlayer } = global;

        if (!MediaElementPlayer) {
            return;
        }

        this.ref.current.addEventListener("fullscreenchange", (event) => {
            if (document.fullscreenElement) {
                document.querySelector("#danmu").style.width = "100%";
            } else {
                document.querySelector("#danmu").style.width = "70rem"
            }
        })
        this.setState({
            player: new MediaElementPlayer(this.props.id, {
                forceLive: true,
                features: ["playpause", "duration", "progress", "airplay", "volume"],
            })
        });
    }

    componentWillUnmount() {
        if (this.state.player) {
            this.setState({ player: null });
        }
    }
}

class Live extends Component {

    render() {
        return <div className="w-280 mx-auto">
            <div className="flex flex-col h-screen">
                <Meta {...this.props.location} platform={this.props.platform} />
                <MediaElement
                    id="player1"
                    mediaType="video"
                    preload="none"
                    poster=""
                    path={this.props.location.pathname}
                    type={this.props.type}
                />
                <ToolBar />
            </div>
        </div>

    }
}

export default Live;