import { Component } from "react";
import { Link } from "react-router-dom";

class Room extends Component {
    render() {
        return <Link to={`/${this.props.platform}/${this.props.room_id}`}>
            <div className="flex cursor-pointer">
                <img referrerPolicy="no-referrer" src={this.props.avatar} alt="" className=" w-14 h-14 mx-5 my-3" />
                <div className="flex-wrap flex w-44">
                    <h3 className="text-xl mt-2 mb-1 w-44">{this.props.author}</h3>
                    <span className="truncate flex-auto">{this.props.users === "0" ? "离线中" : this.props.title}</span>
                </div>
            </div>
        </Link>
    }
}

class PlayList extends Component {
    constructor(props) {
        super(props)
        this.collapsed_platform = this.collapsed_platform.bind(this);
        this.state = {
            playlist: [],
            collapsed: true
        }
    }
    collapsed_platform = async (platform) => {
        let platList = window.localStorage.getItem(platform) || ""
        platList = platList.split(",").filter((x) => x !== "")
        console.log(platList)
        if (platList.length === 0 || !this.state.collapsed) {
            this.setState((prev, props) => {
                return {
                    collapsed: !prev.collapsed,
                    playlist: []
                };
            })
            return
        }
        const resp = await fetch(`/api/${platform}/${platList}?only=info`)
        const body = await resp.json()
        const playArray = platList
        this.setState((prev, pros) => {
            return {
                collapsed: !prev.collapsed,
                playlist: body.map((x, i) => { x['room_id'] = playArray[i]; return x })
            }
        })
    }
    render() {
        // this.props.collapsed;
        return <div className="my-4">
            <div className="inline-flex items-center w-full">
                <img referrerPolicy="no-referrer" src={this.props.icon} alt="" className="w-7 h-7 mx-5" />
                <span className="text-xl flex-grow">{this.props.desc}</span>
                <div className="h-7 w-7 mx-5 cursor-pointer"
                    onClick={async () => { await this.collapsed_platform(this.props.platform) }}
                >

                    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={this.state.collapsed ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"} />
                    </svg>

                </div>
            </div>

            <ul>
                {this.state.playlist.map((item, i) => <li>
                    <Room {...item} platform={this.props.platform} update_current={this.props.update_current} />
                </li>)}
            </ul>
        </div >
    }
}

class LeftSide extends Component {
    render() {
        return <div id="sidebar" className="flex-none w-72 text-gray-50 bg-gray-800 z-20 hidden lg:block overflow-y-scroll">
            <h1 className="text-3xl text-center my-8">聚合直播</h1>
            <PlayList platform="huya" desc="虎牙直播" icon="https://www.huya.com/favicon.ico" />
            <hr />
            <PlayList platform="bilibili" desc="哔哩哔哩" icon="https://live.bilibili.com/favicon.ico" />
            <hr />
            <PlayList platform="douyu" desc="斗鱼直播" icon="https://www.douyu.com/favicon.ico" />
        </div>
    }
}

export default LeftSide;
