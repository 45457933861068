import { Component } from "react";
import InputStream from "./huya";

import BulletJs from "js-bullets";

class Header extends Component {
    constructor(props) {
        super(props)
        this.subs = this.subs.bind(this)
        this.state = {
            isSub: false
        }
    }
    componentDidMount() {
        this.plat = window.location.pathname.split("/")[1]
        this.room_id = window.location.pathname.split("/")[2]
        const playitems = window.localStorage.getItem(this.plat) || ""
        this.setState({
            isSub: playitems.search(this.room_id) !== -1
        })
    }
    subs() {
        const items = window.localStorage.getItem(this.plat) || ""
        const all = new Set(items.split(","))
        if (this.state.isSub) {
            all.delete(this.room_id)
        } else {
            all.add(this.room_id)
        }
        window.localStorage.setItem(this.plat, Array.from(all))
        this.setState((prev, props) => {
            const newS = !prev.isSub
            return { isSub: newS }
        })
    }
    render() {
        const props = this.props;
        return <div className="flex-grow">
            <div className="flex mb-2">
                <div className="lg:text-2xl text-xl text-gray-200 flex-grow"><h2>{props.title}</h2></div>
                <div className=" rounded-3xl bg-gray-200 text-gray-800 px-6 py-2 text-base lg:text-xl">
                    <button onClick={this.subs}>{this.state.isSub ? "取消关注" : "关注"}</button>
                </div>
            </div>
            <div className="flex text-lg text-gray-100">
                <div className="flex-grow">{props.platform} - {props.author}</div>
                <div className="items-center flex-wrap inline-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <div className="text-lg">{parseInt(props.users).toLocaleString()}</div>
                </div>
            </div>
        </div>
    }
}

class Meta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            danmu: false
        }
        this.initDanmu = this.initDanmu.bind(this)
    }
    initDanmu() {
        if (!this.props.pathname.startsWith("/huya")) return

        this.screen = new BulletJs("#danmu", {})
        this.ws = new WebSocket("wss://cdnws.api.huya.com/")

        this.ws.onopen = () => {
            var init = new Uint8Array(this.state.danmu_init)
            this.ws.send(init.buffer)
            console.log("开始接受弹幕")
        }

        setInterval(() => {
            const source = [0, 3, 29, 0, 0, 105, 0, 0, 0, 105, 16, 3, 44, 60, 76, 86, 8, 111, 110, 108, 105, 110, 101, 117, 105, 102, 15, 79, 110, 85, 115, 101, 114, 72, 101, 97, 114, 116, 66, 101, 97, 116, 125, 0, 0, 60, 8, 0, 1, 6, 4, 116, 82, 101, 113, 29, 0, 0, 47, 10, 10, 12, 22, 0, 38, 0, 54, 7, 97, 100, 114, 95, 119, 97, 112, 70, 0, 11, 18, 3, 174, 240, 15, 34, 3, 174, 240, 15, 60, 66, 109, 82, 2, 96, 92, 96, 1, 124, 130, 0, 11, 176, 31, 156, 172, 11, 140, 152, 12, 168, 12, 32]
            const hb = new Uint8Array(source);
            this.ws.send(hb.buffer)
        }, 60000)

        this.ws.onmessage = async (event) => {
            let buf = await event.data.arrayBuffer(),
                s = new Uint8Array(buf),
                is = new InputStream(s);
            s = is.readBytes(1, false)
            is = new InputStream(s)
            s = is.readBytes(2, false)
            is = new InputStream(s)
            is.readStruct(0)
            // var name = new TextDecoder("utf-8").decode(new Uint8Array());
            var content = new TextDecoder("utf-8").decode(new Uint8Array(is.readString(3, false)));
            this.screen.push(content)
        }
        this.setState({ danmu: true })
    }
    fetch_room = async () => {
        const path = this.props.pathname;
        const resp = await fetch(`/api${path}?only=info`)
        const body = await resp.json()
        document.title = `${body[0].title} - ${this.props.platform}`
        this.setState(body[0])
        if (!this.state.danmu)
            this.initDanmu()
    }
    componentDidMount() {
        this.fetch_room()
        this.interval = setInterval(() => {
            this.fetch_room()
        }, 60000);
    }
    componentWillUnmount() {
        this.ws && this.ws.close()
        clearInterval(this.interval)
    }
    render() {
        return <div className="text-gray-50 mt-5 mb-3 flex justify-center items-center">
            <img referrerPolicy="no-referrer" alt="" src={this.state.avatar} className="flex-none w-24 h-24 hidden lg:block lg:p-2 mr-4" />
            <Header {...this.state} platform={this.props.platform} />
        </div>
    }
}

export default Meta;