import { Component } from "react";
import React from "react";


class LockPage extends Component {
    constructor(props) {
        super(props)
        this.array = ["", "", "", ""]
        this.first = React.createRef()
        this.second = React.createRef()
        this.third = React.createRef()
        this.fourth = React.createRef()
        this.login = props.login;
        this.try = props.try;
    }

    componentDidMount() {
        this.try()
    }

    render() {
        return <div className="text-center flex justify-center h-auto align-middle">
            <div className="mb-auto my-auto mt-40 lg:mt-64">
                <h2 className="mb-10 text-gray-200 text-2xl">请输入密码：</h2>
                <div id="otp" className="flex justify-center">
                    <input autoFocus ref={this.first}
                        onChange={value => { if (value.target.value !== "") { this.second.current.focus(); this.array[0] = value.target.value } else { this.array[0] = "" } }}
                        className="m-4 text-2xl lg:w-16 lg:h-16 w-12 h-12 text-center rounded-xl bg-gray-200 form-control focus:shadow-outline focus:outline-none focus:shadow-focus" type="text" id={this.props.order} maxLength="1" />
                    <input ref={this.second}
                        onChange={value => {
                            if (value.target.value === "") {
                                this.array[1] = ""
                                this.first.current.focus()
                            } else {
                                this.array[1] = value.target.value
                                this.third.current.focus()
                            }
                        }} className="m-4 text-2xl lg:w-16 lg:h-16 w-12 h-12 text-center rounded-xl bg-gray-200 form-control focus:shadow-outline focus:outline-none focus:shadow-focus" type="text" id={this.props.order} maxLength="1" />
                    <input ref={this.third} onChange={value => {
                        if (value.target.value === "") {
                            this.array[2] = ""
                            this.second.current.focus()
                        } else {
                            this.array[2] = value.target.value
                            this.fourth.current.focus()
                        }
                    }} className="m-4 text-2xl lg:w-16 lg:h-16 w-12 h-12 text-center rounded-xl bg-gray-200 form-control focus:shadow-outline focus:outline-none focus:shadow-focus" type="text" id={this.props.order} maxLength="1" />
                    <input ref={this.fourth} onChange={value => {
                        if (value.target.value === "") {
                            this.array[3] = ""
                            this.third.current.focus()
                        } else {
                            this.array[3] = value.target.value
                            this.login(this.array)
                        }
                    }} className="m-4 text-2xl lg:w-16 lg:h-16 w-12 h-12 text-center rounded-xl bg-gray-200 form-control focus:shadow-outline focus:outline-none focus:shadow-focus" type="text" id={this.props.order} maxLength="1" />
                </div>
                <h2 className="mt-2 text-2xl text-red-500">{this.props.showErr ? "密码错误！！！" : ""}</h2>
            </div>
        </div>
    }
}


export default LockPage;