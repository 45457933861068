class InputStream {
    constructor(source) {
        this.buffer = source
        this.position = 0
    }
    peekFrom = () => {
        const helper = this.buffer[this.position]
        let t = (helper & 0xF0) >> 4,
            p = helper & 0x0F,
            l = 1;
        if (t >= 15) {
            l = 2
            t = this.buffer[this.position + 1]
        }
        return [t, p, l]
    }

    skipField = (p) => {
        switch (p) {
            case 0:
                this.position++
                break;
            case 1:
                this.position += 2
                break;
            case 2:
                this.position += 4;
                break;
            case 3:
                this.position += 8;
                break;
            case 6:
                const length = this.buffer[this.position];
                this.position += length + 1;
                break
            case 10:
                this.skipToStruct()
                break
            default:
                break;
        }
    }

    skipToStruct() {
        var [t, p, l] = this.readFrom()
        while (p !== 11 && this.position < this.buffer.length) {
            this.skipField(p)
            var [t, p, l] = this.readFrom()
        }

    }

    readStruct = (tag) => {
        if (this.skipToTag(tag)) {
            var [t, p, l] = this.readFrom()
            if (p !== 10) return
            var s = this.readString(2, false)
            this.skipToStruct()
            return s
        }
    }

    skipToTag = (tag) => {
        if (this.buffer === undefined) return
        let length = this.buffer.length;
        while (this.position < length) {
            let [t, p, l] = this.peekFrom()
            if (tag <= t || p === 11) {
                if (p === 11) return false
                return t === tag
            }
            this.position += l;
            this.skipField(p)
        }
        return false
    }

    readFrom = () => {
        const [t, p, l] = this.peekFrom()
        this.position += l
        return [t, p, l]
    }

    readInt32 = (tag) => {
        if (this.skipToTag(tag)) {
            const [t, p, l] = this.readFrom();
            if (p === 12) return 0
            else if (p === 0) {
                return this.buffer[this.position++]
            } else if (p === 1) {
                const value = intFromBytes(this.buffer.slice(this.position, this.position + 2), 2)
                this.position += 2
                return value
            } else if (p === 2) {
                const value = intFromBytes(this.buffer.slice(this.position, this.position + 4))
                this.position += 4
                return value
            }
        }
    }
    readString = (tag, require) => {
        if (this.skipToTag(tag)) {
            const [t, p, l] = this.readFrom()
            if (p === 6) {
                const length = this.buffer[this.position];
                this.position++;
                const value = this.buffer.slice(this.position, this.position + length);
                this.position += length
                return value
            } else if (p === 7) {
                const length = intFromBytes(this.buffer.slice(this.position, this.position + 4), 4);
                this.position += 4;
                const value = this.buffer.slice(this.position, this.position + length);
                this.position += length
                return value
            }
            return
        }
    }
    readBytes = (tag, require) => {
        if (this.skipToTag(tag)) {
            let [t, p, l] = this.readFrom()
            if (p === 13) {
                const [ti, pi, li] = this.readFrom();
                if (pi !== 0) return
                const size = this.readInt32(0);
                const value = this.buffer.slice(this.position, this.position + size);
                this.position += size;
                return value
            }
            return
        }
    }
}
// function getIntBytes(x, size) {
//     let y = Math.floor(x / 2 ** (size * 8));
//     return [x, (x << 8), (x << 16), (x << 24)].map(z => z >>> 24)
// }

const intFromBytes = (byteArr, size) => {
    return byteArr.reduce((a, c, i) => a + c * 2 ** ((size - i - 1) * 8), 0)
}

// module.exports = exports = InputStream;

export default InputStream;