import { Component } from "react";
import { Link } from "react-router-dom";

class TimeModal extends Component {
    constructor(p) {
        super(p)
        this.state = { "value": "" }
        this.handleChange = this.handleChange.bind(this)
        this.setTimer = this.setTimer.bind(this)
    }
    handleChange(event) {
        console.log("fjldjfld")
        this.setState({ "value": event.target.value })
    }
    setTimer() {
        console.log(this.state.value)
        setTimeout(() => {
            window.open("about:blank", "_self");
        }, this.state.value * 60 * 1000)
        this.props.close()
    }
    render() {
        if (!this.props.show) {
            return <div />
        }
        const timer = [1, 2, 3, 4, 5, 6]
        return <div className=" cursor-default fixed top-0 right-0 bottom-0 left-0 z-10 transition-all duration-300 bg-gray-400 bg-opacity-50">
            <div className="flex justify-center w-full h-full items-center">
                <div className="lg:w-1/3 w-full lg:h-2/5 space-y-5 py-10 bg-gray-800 rounded-2xl flex flex-col">
                    <input placeholder="输入数字（单位：分钟）" className="text-gray-800 text-2xl p-2 rounded-2xl lg:w-2/3 mx-auto" value={this.state.value} onChange={this.handleChange} />
                    <div className="space-x-4 space-y-4 text-lg flex-grow">
                        <span>常用时间：</span>
                        {timer.map((item) =>
                            <button onClick={this.handleChange} className="px-3 py-2 bg-gray-600 rounded-2xl" value={item * 20}>{item * 20}</button>
                        )}
                    </div>
                    <div className="mb-5 space-x-32">
                        <button
                            className="w-20 h-12 shadow-2xl border rounded-2xl"
                            onClick={this.props.close}
                        >取消</button>
                        <button className=" w-20 h-12 shadow-2xl border rounded-2xl" onClick={this.setTimer}>确定</button>
                    </div>
                </div>
            </div>
        </div >
    }
}

class NewLiveModal extends Component {
    constructor(props) {
        super(props)
        this.state = { show: false, live: "" }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(event) {
        let url;
        try {
            url = new URL(event.target.value)
        } catch (_) {
            return
        }
        if (url.host.search("huya") !== -1) {
            return this.setState({ live: `/huya${url.pathname}` })
        } else if (url.host.search("bili") !== -1) {
            return this.setState({ live: `/bilibili${url.pathname}` })
        } else if (url.host.search("douyu") !== -1) {
            return this.setState({ live: `/douyu${url.pathname}` })
        }
    }

    render() {
        if (!this.props.show) {
            return <div />
        }
        console.log("Render number", this.state.live)
        return <div className=" cursor-default fixed top-0 right-0 bottom-0 left-0 z-10 transition-all duration-300 bg-gray-400 bg-opacity-50">
            <div className="flex justify-center w-full h-full items-center">
                <div className="lg:w-1/3 w-full lg:h-2/5 space-y-5 py-10 bg-gray-800 rounded-2xl flex flex-col">
                    <div className="flex-grow">
                        <input placeholder="输入斗鱼、虎牙、哔哩哔哩直播 URL" className="text-2xl text-gray-800 p-2 rounded-2xl lg:w-2/3 mx-auto" onChange={this.handleChange} />
                    </div>
                    <div className="mb-5 space-x-32">
                        <button
                            className="w-20 h-12 shadow-2xl border rounded-2xl"
                            onClick={this.props.close}
                        >取消</button>
                        <button className=" w-20 h-12 shadow-2xl border rounded-2xl">
                            <Link to={this.state.live}>前往</Link>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    }
}

class TimeToClosed extends Component {
    constructor(props) {
        super(props)
        this.state = { show: false }
    }
    showOptions = () => {
        this.setState({ show: true })
    }
    closeOptions = () => {
        this.setState({ show: false })
    }
    render() {
        return <div
            className=" cursor-pointer self-center text-center rounded-xl p-1 w-20 transition-all duration-300 hover:bg-gray-700 hover">
            <div
                onClick={this.showOptions}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="text-sm">
                    定时关闭
                </span>
            </div>
            <TimeModal {...this.state} close={this.closeOptions} />
        </div>
    }
}

class AddNew extends Component {
    constructor(props) {
        super(props)
        this.state = { show: false }
    }
    showOptions = () => {
        this.setState({ show: true })
    }
    closeOptions = () => {
        this.setState({ show: false })
    }
    render() {
        return <div
            className="cursor-pointer self-center text-center rounded-xl p-1 w-20 transition-all duration-300 text-gray-100 hover:bg-gray-700 hover">
            <div
                onClick={this.showOptions}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="text-sm">
                    添加直播
                </span>
            </div>
            <NewLiveModal {...this.state} close={this.closeOptions} />
        </div>
    }
}

class ToggleSideBar extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            show: false
        }
    }
    toggle() {
        let clsList = document.querySelector("#sidebar").classList;
        this.setState((prev, props) => {
            if (prev.show) {
                clsList.replace("sidebar-style", "hidden")
            } else {
                clsList.replace("hidden", "sidebar-style")
            }
            return { show: !prev.show }
        })
    }
    render() {
        return <div
            className="lg:hidden cursor-pointer self-center text-center rounded-xl p-1 w-20 transition-all duration-300 text-gray-100 hover:bg-gray-300 fixed top-5/6 right-0 bottom-0 left-4/5 z-20">
            <div
                onClick={this.toggle}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
                <span className="text-sm">
                    侧栏订阅
                </span>
            </div>
        </div>
    }
}

class ToolBar extends Component {
    render() {
        return <div>
            <div className="mt-5 space-x-6 inline-flex text-gray-100">
                <TimeToClosed />
                <AddNew />
            </div>
            <ToggleSideBar />
        </div>
    }
}

export default ToolBar;
export { AddNew, ToggleSideBar };
