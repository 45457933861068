import './App.css';

import { Component } from 'react';
import LockPage from './Lock';
import LeftSide from './left';
import Live from './Live';
import { AddNew, ToggleSideBar } from "./Tool"

import { BrowserRouter, Route } from "react-router-dom";

class Note extends Component {
  render() {
    return <div className="flex justify-center w-full items-center">
      <div className="pb-36 inline-flex items-center  ">
        <AddNew />
        <ToggleSideBar />
      </div>
    </div>
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      "locked": true,
      showErr: false
    }
    this.login = this.login.bind(this);
    this.try = this.try.bind(this)
    this.url = "/api/login"
    this.room_url = ""
  }

  try = async () => {
    const resp = await fetch(`/api/try?${Math.random()}`)
    if (resp.status === 404 || resp.status === 200) {
      this.setState((prev, props) => {
        const newState = !prev.locked;
        return { locked: newState }
      })
    }
  }

  login = async (array) => {
    const pass = array.join("")

    const resp = await fetch(this.url, {
      method: 'POST',
      body: JSON.stringify({ 'auth': pass }),
      headers: new Headers({
        'content-type': 'application/json',
      })
    })
    if (resp.status === 200) {
      this.setState((prev, props) => {
        const newState = !prev.locked;
        return { locked: newState }
      })
    } else {
      this.setState({ showErr: true })
    }
  }

  render() {
    if (this.state.locked) {
      return <LockPage login={this.login} showErr={this.state.showErr} try={this.try} />
    }
    return <BrowserRouter>
      <div className="h-screen flex">
        <LeftSide />
        <Route exact path="/" component={Note} />
        <Route path="/huya/:room_id" render={(props) => <Live platform="虎牙直播" {...props} type='application/x-mpegURL' key={Math.random() * 16} />} />
        <Route path="/bilibili/:room_id" render={(props) => <Live platform="哔哩哔哩" {...props} type='application/x-mpegURL' key={Math.random() * 16} />} />
        <Route path="/douyu/:room_id" render={(props) => <Live platform="斗鱼直播" {...props} type="video/flv" key={Math.random() * 16} />} />
      </div>
    </BrowserRouter>
  }

}
export default App;
